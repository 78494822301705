import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  const manual = (`
      <div>
        <h5>1. Mietpreise</h5>
        Es gelten die Preise der gültigen Mietpreisliste. <br/><br/>
        <h5>2. Mietzeitraum</h5>
        Der Mietzeitraum ist die Zeit von der vereinbarten Übernahme bis zur endgültigen Rückgabe des Fahrzeugs. Die Rückgabe des Fahrzeugs hat bis Spätestens 17.00 Uhr zu erfolgen. Bei Rückgabe nach 17.00 Uhr wird ein zusätzlicher Miettag berechnet. Eine Übernahme nach Eintritt der Dunkelheit wird nicht durchgeführt. Soweit der Mieter das Fahrzeug außerhalb der üblichen Geschäftszeiten abstellt und die Fahrzeugpapiere und -schlüssel in den Briefkasten des Vermieters einwirft, so gilt das Fahrzeug hiermit noch nicht als an den Vermieter zurückgegeben. Erst mit Rückgabe des Fahrzeugs, durch einen Mitarbeiter oder des Inhabers, geht die Gefahr wieder auf den Vermieter über. Für Schäden und Verlust, die im Zeitpunkt zwischen Abstellen des Fahrzeugs durch den Mieter und der Möglichkeit der Begutachtung des Fahrzeugs durch den Vermieter zu den üblichen Geschäftszeiten am Fahrzeug entstehen, haftet der Mieter vollumfänglich im Rahmen der üblichen Bestimmungen des Vertrages und dieser Geschäftsbedingungen.<br/><br/>
        <h5>3. Zahlungsweise</h5>
        Bei Ausfertigung der Mietvereinbarung ist eine Anzahlung von 500 € zu leisten. Die Restmiete ist 60 Tage vor Reisebeginn fällig und wird per Rechnungsversand eingefordert. Bei Übernahme des Fahrzeugs ist eine Kaution von 1500 € (Vollkasko Eigenbeteiligung) in bar zu hinterlegen oder bereits mit dem Restbetrag des Gesamtmietpreises zu überweisen. Sie wird nach endgültiger Rückgabe und Verrechnung aller entstandenen Kosten zurückbezahlt. Kreditkartenzahlungen sind ebenfalls möglich, diese Kosten jedoch 3% Bearbeitungsgebühr.<br/>
        <br/>
        <h5>3.1 Kaution</h5>
        Die Kaution wird bei vertragsgemäßer und pünktlicher Rückgabe sofort an den Mieter
        ausgezahlt. Die Kautionsrückzahlung enthebt den Mieter nicht von der Haftung für
        verdeckte oder bei Fahrzeugrücknahme nicht sofort feststellbare Mängel. Fehlende
        Gegenstände, Beschädigungen, ausstehende Mietforderungen, Schadenersatzansprüche wegen unsachgemäßem Gebrauch
        Forderungen werden mit der Kaution verrechnet. Kommt der Mieter den vertraglichen Rückgabeverpflichtungen
        nicht oder nur teilweise nach, werden die Kosten zur Wiederherstellung des vertraglichen Zustandes berechnet.<br/><br/>
        <h5>4. Berechtigte Fahrer</h5>
        Der Fahrer muß mindestens ein Jahr im Besitz einer gültigen Fahrerlaubnis der Klasse 3 bzw. der deutschen Klasse „B“ sein. <br/>
        Der Mieter verpflichtet sich, den Wagen schonend und pfleglich zu behandeln, rücksichtsvoll zu fahren und nicht schuldhaft gegen Verkehrsgesetze zu verstoßen.
        Der Wagen ist nach jeder Fahrtunterbrechung ordnungsgemäß abzustellen und abzuschließen. Der Wagen darf nicht überladen werden. Öl, Wasserstand und Reifendruck
        sind während der Mietdauer regelmäßig zu kontrollieren. Der Mieter hat unbedingt die vorgeschriebenen maximalen Durchfahrtshöhen und –breiten zu beachten. Verletzt
        der Mieter diese Pflichten, haftet er für die daraus entstehenden Schäden.<br/><br/>
        <h5>5. Verhalten bei Unfällen</h5>
        Der Mieter hat nach jedem Unfall umgehend die Polizei und den Vermieter zu verständigen. Ein Unfallbericht ist komplett mit allen Daten zu erstellen und muss sofort nach Rückgabe des Fahrzeuges dem Vermieter ausgehändigt werden. Geschieht dies nicht, haftet der Mieter für Schäden die nicht durch die Haftpflichtversicherung gedeckt sind. Es dürfen vom Mieter keinerlei gegnerische Ansprüche bei Verkehrsunfällen anerkannt werden.<br/><br/>
        <h5>6. Versicherungsschutz</h5>
        Das angemietete Fahrzeug ist Vollkasko mit 1500 € Selbstbeteiligung und mit Teilkasko 1000 € pro Schaden versichert. Von der Versicherung nicht gedeckt sind Schäden an der Inneneinrichtung. Motoren und Fahrwerkschäden, die auf grob fahrlässige Behandlung des Mieters zurückzuführen sind, (fahren mit zu niedrigem Ölstand, Überdrehen des Motors, Befahren ungeeigneter Weg etc.) sind vom Mieter zu tragen. Diebstahlschutz ist in der Vollkasko vorhanden.<br/>Sie haben die Möglichkeit zur Senkung der Selbstbeteiligung im Schadensfall, durch eine Zuzahlung auf den Mietvertag vor Übernahme des Fahrzeuges.<br/><br/>
        <h5>7. Haftung des Mieters</h5>
        Das angemietete Fahrzeug darf nur für den vereinbarten Zweck verwendet werden. Der Mieter ist verpflichtet das angemietete Fahrzeug zum vereinbarten Zeitpunkt zurückzubringen. Sofern dies nicht geschieht, sind vom Mieter die Kosten für weitere Anmietung, evtl. Rücktransporte zu tragen. <br/><br/>
        Erhält der Mieter während der Mietzeit einen Strafzettel, haftet er dafür selbst. Je Strafzettel fällt eine Bearbeitungsgebühr von 10,-€ an, sofern bei Rückgabe angekündigt wird, dass ein Strafzettel zu erwarten ist. Wird dies nicht mitgeteilt beträgt die Bearbeitungsgebühr 20,-€. Die Rechnung wird in diesem Fall per Post inklusive Strafzettel zugestellt.<br/><br/>
        Rauchen ist in unseren Fahrzeugen nicht erlaubt. Eine Zuwiderhandlng führt eine Strafe von 500€ nach sich.<br/><br/>
        <h5>8. Leistung des Vermieters</h5>
        Der Vermieter stellt das Fahrzeug zum angemieteten Zeitpunkt bereit. Sollte das bestellte Fahrzeug aus irgendeinem Grund nicht verfügbar sein, stellt er ein entsprechendes Ersatzfahrzeug oder erstattet die geleisteten Zahlungen. Schadensersatzansprüche des Mieters gegenüber dem Vermieter bestehen nicht. <br/><br/>
        <h5>9. Rücktritt vom Mietvertrag</h5>
        Bei Rücktritt nach Ausfertigung der Mietvereinbarung und Eingang der fälligen Anzahlung, behält sich Wohnmobile Kraus pauschal 50,00 € Rücktrittsgebühren ein. Bei Rücktritt 120 Tage vor Reisebeginn sind 50 %, ab 30 Tage vor Reisebeginn ist der volle Mietpreis zu bezahlen. Bei verschuldeter oder unverschuldeter Nichtabholung ist der volle Mietpreis fällig und der Mieter hat keinen Anspruch mehr auf das Fahrzeug.<br/><br/>
        <h5>10. Reinigung des Fahrzeugs</h5>
        Die Servicepauschale enthält die Aussen-Reinigung. Die Innenreinigung ist v. Mieter durchzuführen.. Falls die Innenreinigung ganz oder teilweise durchzuführen ist, werden hierfür Reinigungsgebühren in Höhe v. € 100.— fällig und werden von der Bar-Kaution einbehalten. <br/><br/>
        <h5>11. Auslandsfahrten</h5>
        Auslandsfahrten innerhalb Europas sind möglich. Ost- und außereuropäische Länder bedürfen der vorherigen Einwilligung des Vermieters und der Beantragung eines zusätzlichen Versicherungsschutzes. <br/><br/>
        <h5>12. Kilometerbegrenzung</h5>
        Bei Übertretung der Kilometerbegrenzung werden die gefahrenen Mehrkilometer direkt mit der hinterlegten Kaution verrechnet und Ihnen wird der Rest der Kaution ausbezahlt. <br/><br/>
        <h5>13. Gerichtsstand</h5>
        Ist für beide Parteien München. Es gilt deutsches Recht.
      </div>
  `);

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {'Mietbedingungen'}
              </h2>
              <PageContent className="content" content={manual} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
